@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');


.tags-input-container{
    border: 1px solid #000;
    padding: .5em;
    border-radius: 9999px;
    min-height: 2.5rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    width: 100%;
}

.tag-item{
    background-color: black;
    display: inline-block;
    margin-top: 5px;
    padding: 5px 1rem;
    border-radius: 20px;
    color: white;
    text-transform: uppercase;
    font-family: 'IBM Plex Sans';
}
.tag-item .close{
    height: 20px;
    width: 20px;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: .5em 10px;
    border: none;
    outline: none;
    width: auto;
    background-color: transparent;
}

.btn-principal {
    background-color: black;
    color: white;
    font-family: 'IBM Plex Sans';
    text-transform: uppercase;
}
.btn-secundario {
    background-color: white;
    color: black;
    border: solid 1px black;
    font-family: 'IBM Plex Sans';
    text-transform: uppercase;
}
